import * as React from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment-timezone';
import {
  defaultTo,
  get,
  includes,
  isEmpty,
  isNil,
  omit,
  trim,
  isEqual,
  some,
  forEach,
  debounce
} from 'lodash';
import { Button, FormControl, MuiThemeProvider } from '@material-ui/core';
import {
  customInputRight,
  customInputTheme,
  customInputThemeSearch,
  customSelectTheme,
  IconSearchHolder,
  ProductTypeSelectField,
  ProductTypeSelectLeft,
  ProductTypeSelectRight,
} from '../ConsumerItem/ConsumerItem.styled';
import {
  PackInfoItem,
  PackInfoItemMax,
  PackInfoItemMin,
  ProductSearchBtn,
  ModerationIconHolder 
} from '../../Product/Product.styled';
import {
  ProductButton,
  ProfileAutocomplete,
  TextTooltipModeration,
  TextTooltipModerationUpper
} from '../../../../../common/styles/Common.styled';
import {
  IconMinus,
  IconPlus,
  IconSearchBlack,
  IconDelete,
  IconError
} from '../../../../../common/icons/customIcons';
import {
  AutocompleteItem,
  InputItem,
  SelectItem,
  SelectTypeItem,
} from './index';
import { ModerateTooltip } from '../../../../../components/tooltips';
import { config, selectLang } from '../../../../../config';
import { Tooltip, Typography } from '@material-ui/core';

const getDefaultTo = (obj, path, defaultValue = '') =>
  defaultTo(get(obj, path, defaultValue), defaultValue);

const isoDateRegExp = new RegExp(
  /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/,
);

const isISODate = (str) => isoDateRegExp.test(str);

/**
 * TODO:
 * разобраться. почему то не срабатывает перевод. если добавить несколько блоков, а потом переключить язык.
 */
const ButtonDelete = (args) => {
  const { attrUid, disabled, deleteItem, t, item } = args;
  const hasMultiDelete = item.multiplicityDelete &&
  (item.multiplicityGroupId || item.multiplicityGroupUiId)

  const style = hasMultiDelete ? { minWidth: 50, border: '1px solid #868686' } : { border: '1px solid #868686' }

  return (
    <PackInfoItemMin>
      <MuiThemeProvider theme={ProductButton}>
        <Button
          disabled={disabled}
          color={'danger'}
          style={style}
          onClick={() => deleteItem(attrUid)}>
          {!hasMultiDelete && <IconMinus style={{ marginRight: '15px' }} />}
          {hasMultiDelete
            ? <IconDelete />
            : t('Удалить')}
        </Button>
      </MuiThemeProvider>
    </PackInfoItemMin>
  );
};

/**
 * TODO: удалить, если отключение этого параметра не нанесёт урона бизнесс логике.
 *
 * Скрыто, т.к. рудимент от форкнутой репы.
 * const d использовался для определения disabled.
 */
// const isCheckDisabledForGs1 = (args, value) => {
//     const { item, hasPublishedGood } = args;
//     const { isFromGs1, isGtinAffect } = item;
//     return (isFromGs1 || hasPublishedGood) && isGtinAffect && !!value; // KAT-5239
// };

const _generatePropsAutocomplete = (args) => {
  const {
    index,
    item,
    attrUid,
    idIndex,
    suggestions,
    errors,
    isModerateError,
    handleClearFieldAutocomplete,
    getSuggestionValue,
    onValidate,
    onBlur,
    error,
  } = args;

  return {
    ...args,
    attrUid: attrUid,
    id: idIndex,
    itemId: item.id,
    name: item.name,
    type: item.fieldType,
    description: item.description,
    suggestions: suggestions,
    errors: errors,
    error: isModerateError || error,
    onValidate: onValidate,
    handleClearField: () => handleClearFieldAutocomplete(index),
    getSuggestionValue: (value) => getSuggestionValue(value, index),
    onBlur: onBlur,
  };
};

const AutocompleteType1 = (args) => {
  const {
    index,
    item,
    disabled,
    valueType,
    valueTitle,
    handleSuggestionsFetchRequested,
    itemValue,
  } = args;
  const props = _generatePropsAutocomplete(args);
  // const d = isCheckDisabledForGs1(args, valueTitle);
  const v = item.id === 2504 ? itemValue : valueTitle;
  return (
    <MuiThemeProvider theme={customInputRight}>
      <AutocompleteItem
        {...props}
        disabled={disabled || !valueType}
        value={v}
        handleSuggestionsFetchRequested={(value) =>
          handleSuggestionsFetchRequested(value, !item.isPresetOnly, index)
        }
      />
    </MuiThemeProvider>
  );
};

const AutocompleteType2 = (args) => {
  const {
    index,
    item,
    disabled,
    required,
    itemValue,
    valueTitle,
    handleSuggestionsFetchRequested,
  } = args;
  const props = _generatePropsAutocomplete(args);
  const v = includes([2813, 2817], item.id) ? itemValue : valueTitle; //KAT-4812
  /**
   * 13747 - поле "Страна производства"
   * чтобы не ломался бэк-энд, свойство onlyFromList должно быть активировано только для этого поля.
   * молимся что id не изменится.
   */
  const onlyFormList = item.id === 13747;
  return (
    <AutocompleteItem
      {...props}
      disabled={disabled}
      required={required}
      value={v}
      onlyFormList={onlyFormList}
      handleSuggestionsFetchRequested={(value) =>
        handleSuggestionsFetchRequested(value, !item.isPresetOnly, index)
      }
    />
  );
};

const AutocompleteType3 = (args) => {
  const {
    index,
    disabled,
    itemValue,
    valueType,
    handleSuggestionsFetchRequested,
  } = args;
  const props = _generatePropsAutocomplete(args);
  // const d = isCheckDisabledForGs1(args, itemValue);
  return (
    <MuiThemeProvider theme={customInputRight}>
      <AutocompleteItem
        {...props}
        disabled={disabled || !valueType}
        value={itemValue}
        handleSuggestionsFetchRequested={(value) =>
          handleSuggestionsFetchRequested(value, true, index)
        }
      />
    </MuiThemeProvider>
  );
};

const AutocompleteType4 = (args) => {
  const {
    index,
    disabled,
    required,
    itemValue,
    handleSuggestionsFetchRequested,
  } = args;
  const props = _generatePropsAutocomplete(args);
  return (
    <MuiThemeProvider theme={ProfileAutocomplete}>
      <AutocompleteItem
        {...props}
        disabled={disabled}
        required={required}
        value={itemValue}
        handleSuggestionsFetchRequested={(value) =>
          handleSuggestionsFetchRequested(value, true, index)
        }
      />
    </MuiThemeProvider>
  );
};

const _generatePropsSelectItem = (args) => {
  const {
    index,
    item,
    attrUid,
    idIndex,
    itemValue,
    isModerateError,
    handleChange,
    error,
  } = args;
  return {
    ...args,
    attrUid: attrUid,
    id: idIndex,
    name: item.name,
    placeholder: item.name,
    description: item.description,
    preset: item.preset,
    value: itemValue,
    error: isModerateError || error,
    onChange: (event) => handleChange(event.target.value, 'select', index),
  };
};

const SelectItemType1 = (args) => {
  const { disabled, valueType } = args;
  const props = _generatePropsSelectItem(args);
  // const d = isCheckDisabledForGs1(args, props.value);
  return (
    <MuiThemeProvider theme={customInputTheme}>
      <SelectItem {...props} disabled={disabled || !valueType} />
    </MuiThemeProvider>
  );
};

const SelectItemType2 = (args) => {
  const { disabled, required } = args;
  const props = _generatePropsSelectItem(args);
  return <SelectItem {...props} disabled={disabled} required={required} />;
};

const _generatePropsInputItem = (args) => {
  const {
    index,
    item,
    attrUid,
    idIndex,
    valueOrDate,
    isModerateError,
    error,
    errors,
    onValidate,
    handleChange,
    defaultValueAddress = '',
    resizeable,
  } = args;
  return {
    ...args,
    attrUid: attrUid,
    id: idIndex,
    itemId: item.id,
    name: item.name,
    label: item.name,
    placeholder: item.name,
    fieldType: item.fieldType,
    description: item.description,
    value: valueOrDate,
    errors: errors,
    error: isModerateError || error,
    onValidate: onValidate,
    onChange: (value) => handleChange(value, 'input', index),
    onUpdate: () => {},
    defaultValueAddress: defaultValueAddress,
    modalType: item.modalType,
    multiline: resizeable,
  };
};

const InputItemType1 = (args) => {
  const { disabled, valueType, editableAddressBlock, defaultValueCountry, id } = args;
  const props = _generatePropsInputItem(args);
  const disableInConsumerModal =
    props.modalType &&
    props.modalType === 'createConsumerPackage' &&
    !editableAddressBlock;
  // const d = isCheckDisabledForGs1(args, props.value);
  return (
    <MuiThemeProvider theme={customInputTheme}>
      {props.modalType && props.modalType === 'createConsumerPackage' ? (
        <InputItem
          {...props}
          disabled={id === 15677 ? disabled || disableInConsumerModal || !defaultValueCountry : disabled || !valueType || disableInConsumerModal }
        />
      ) : (
        <InputItem {...props} disabled={disabled || !valueType} />
      )}
    </MuiThemeProvider>
  );
};

const InputItemType2 = (args) => {
  const { disabled, required } = args;
  const props = _generatePropsInputItem(args);
  return <InputItem {...props} disabled={disabled} required={required} />;
};

const SelectTypeItemType = (args) => {
  const {
    index,
    onBlur,
    item,
    attrUid,
    idIndex,
    disabled,
    required,
    valueType,
    isModerateError,
    valid,
    handleChange,
    defaultValueCountry = '',
    editableAddressBlock,
  } = args;
  const disableInConsumerModal =
    item.modalType &&
    item.modalType === 'createConsumerPackage' &&
    !editableAddressBlock;

  return (
    <ProductTypeSelectLeft>
      {item.modalType && item.modalType === 'createConsumerPackage' ? (
        <SelectTypeItem
          onBlur={onBlur}
          attrUid={attrUid}
          id={idIndex}
          disabled={disableInConsumerModal}
          required={required}
          valueType={valueType}
          valuesTypePreset={item.valuesTypePreset}
          error={isModerateError}
          valid={valid}
          onChange={(event) =>
            handleChange(event.target.value, 'select-type', index)
          }
          defaultValue={defaultValueCountry}
        />
      ) : (
        <SelectTypeItem
          onBlur={onBlur}
          attrUid={attrUid}
          id={idIndex}
          disabled={disabled}
          required={required}
          valueType={valueType}
          valuesTypePreset={item.valuesTypePreset}
          error={isModerateError}
          valid={valid}
          onChange={(event) =>
            handleChange(event.target.value, 'select-type', index)
          }
          defaultValue={defaultValueCountry}
        />
      )}
    </ProductTypeSelectLeft>
  );
};

class GeneralElement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bottom: true,
      defaultValueAddress: '',
      defaultValueCountry: '',
      error: null,
    };
    const tz = moment.tz.guess();
    moment.locale(selectLang(config.lang));
    moment.tz.setDefault(tz);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.t !== nextProps.t) return true
    const equal = [!isEqual(this.state, nextState)];
    const nameProps = [
      'item',
      'index',
      'required',
      'errorField',
      'disabled',
      'suggestions',
      'isModerateError',
      'moderateError',
      'errors',
      'editableAddressBlock',
    ];
    forEach(nameProps, (i) => {
      equal.push(!isEqual(this.props[i], nextProps[i]));
    });
    return some(equal);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.defaultValueAddress !== prevState.defaultValueAddress &&
      nextProps.defaultValueCountry !== prevState.defaultValueCountry
    ) {
      return {
        defaultValueAddress: nextProps.defaultValueAddress,
        defaultValueCountry: nextProps.defaultValueCountry,
      };
    }
    if (nextProps.defaultValueAddress !== prevState.defaultValueAddress) {
      return {
        defaultValueAddress: nextProps.defaultValueAddress,
      };
    }
    if (nextProps.defaultValueCountry !== prevState.defaultValueCountry) {
      return {
        defaultValueCountry: nextProps.defaultValueCountry,
      };
    }
    return null;
  }

  moderateTooltip = (id, title) =>
    ModerateTooltip(id, title, this.state.bottom, this.handleOpen);

  handleOpen = (id) => (event) => {
    const element = document.getElementById(id);
    if (!isNil(element)) {
      const bottom = element.getBoundingClientRect().y > event.clientY;
      if (this.state.bottom !== bottom) {
        this.setState({ bottom });
      }
    }
  };

  handleClearFieldAutocomplete = (index) => {
    this.props.handleChange('', 'autocomplete', index);
  };

  getSuggestionValue = (value, index) => {
    this.props.handleChange(value, 'autocomplete', index);
    return value.text;
  };

  handleChange = (...args) => {
    this.props.handleChange(...args);
    this.onBlur(...args);
  }

  onBlur = debounce((value, ...rest) => {
    const attribute = this.props;
    const { items, setError, searchId } = this.props;

    const artValue = typeof value === 'string' && rest[0] !== 'select-type' ? value : attribute.value;

    if (artValue) {
      const preset = attribute.preset.find(
        (item) => item.id === artValue,
      );

      if (attribute.fieldType === 'date') {
        if (attribute.compareWith) {
          const art = items.find(
            (item) => {
              return item.id === attribute.compareWith.attrId &&
                item.fieldType === 'date' &&
                item.multiplicityGroupId === attribute.multiplicityGroupId && (
                  Boolean(attribute.newBlockId) ? item.newBlockId === attribute.newBlockId : Boolean(item.newBlockId) === false
                )
            },
          );
          if (art?.value) {
            if (attribute.compareWith.operator === 'lt') {
              if (Date.parse(artValue.split('.').reverse().join('.')) >= Date.parse(art.value.split('.').reverse().join('.'))) {
                setError(
                  searchId,
                  `Указанное значение должно быть меньше значения поля ${art.name}.`,
                );

                return;
              } else {
                setError(
                  searchId,
                  null,
                );
                setError(
                  `${art.id}_${art._attruid}_${art.sortOrder}`,
                  null,
                );

                return;
              }
            } else if (attribute.compareWith.operator === 'gt') {
              if (Date.parse(artValue.split('.').reverse().join('.')) <= Date.parse(art.value.split('.').reverse().join('.'))) {
                setError(
                  searchId,
                  `Указанное значение должно быть больше значения поля ${art.name}.`,
                );

                return;
              } else {
                setError(
                  searchId,
                  null,
                );
                setError(
                  `${art.id}_${art._attruid}_${art.sortOrder}`,
                  null,
                );

                return;
              }
            }
          }
        } else {
          const art = items.find(
            (item) => {
              return item.fieldType === 'date' &&
                item.compareWith &&
                item.compareWith.attrId === attribute.id &&
                item.multiplicityGroupId === attribute.multiplicityGroupId && (
                  Boolean(attribute.newBlockId) ? item.newBlockId === attribute.newBlockId : Boolean(item.newBlockId) === false
                )
            },
          );

          if (art?.value) {
            if (art.compareWith.operator === 'lt') {
              if (Date.parse(artValue.split('.').reverse().join('.')) <= Date.parse(art.value.split('.').reverse().join('.'))) {
                setError(
                  searchId,
                  `Указанное значение должно быть больше значения поля ${art.name}.`,
                );

                return;
              } else {
                setError(searchId, null);
                setError(
                  `${art.id}_${art._attruid}_${art.sortOrder}`,
                  null,
                );

                return;
              }
            } else if (art.compareWith.operator === 'gt') {
              if (Date.parse(artValue.split('.').reverse().join('.')) >= Date.parse(art.value.split('.').reverse().join('.'))) {
                setError(
                  searchId,
                  `Указанное значение должно быть меньше значения поля ${art.name}.`,
                );

                return;
              } else {
                setError(searchId, null);
                setError(
                  `${art.id}_${art._attruid}_${art.sortOrder}`,
                  null,
                );

                return;
              }
            }
          }
        }
      }

      if (!preset) {
        const valuesTypeRegex = attribute.valuesTypeRegex && attribute.valuesTypeRegex.find(
          (item) => item.id === attribute.valueType,
        );

        if (valuesTypeRegex) {
          let reg = valuesTypeRegex.text.split('');
          reg[0] = '';
          reg[reg.length - 1] = '';
          const regExp = new RegExp(reg.join(''));

          if (
            // eslint-disable-next-line no-useless-escape
            !regExp.test(
              artValue,
            )
          ) {
            this.setState({ error: 'Указанное значение некорректно.' });
          } else {
            this.setState({ error: null });
          }

          return;
        }

        if (attribute.regExpAllowed) {
          let reg = attribute.regExpAllowed.split('');
          reg[0] = '';
          reg[reg.length - 1] = '';
          const regExp = new RegExp(reg.join(''));
          const regExpAllowed = regExp;
          const checkRegExpAllowed = regExpAllowed.test(artValue);

          if (attribute.regExpAllowedException) {
            let reg = attribute.regExpAllowedException.split('');
            reg[0] = '';
            reg[reg.length - 1] = '';
            const regExp = new RegExp(reg.join(''));
            const regExpAllowedException = regExp;
            const checkRegExpAllowedException = regExpAllowedException.test(
              artValue,
            );

            if (
              (checkRegExpAllowed && !checkRegExpAllowedException) ||
              !checkRegExpAllowed
            ) {
              this.setState({ error: 'Указанное значение некорректно.' });
            } else {
              this.setState({ error: null });
            }
          } else if (!checkRegExpAllowed) {
            this.setState({ error: 'Указанное значение некорректно.' });
          } else {
            this.setState({ error: null });
          }
        } else {
          this.setState({ error: null });
        }
      } else {
        this.setState({ error: null });
      }
    }
  }, 300);

  render() {
    
    const {
      t,
      showPopupSearch,
      index,
      groupIndex,
      attrUid,
      required,
      disabled,
      item,
      isModerateError,
      moderateError,
      errors,
      multiplicity,
      resizeable,
      multiplicityGroupId,
      multiplicityGroupUiId,
      multiplicityDelete,
      setError
    } = this.props;   
    const attribute = this.props;
    const { searchId, items } = this.props;
    let art = null;
    if (attribute.fieldType === 'date') {
      if (attribute.compareWith) {
        art = items.find(
          (item) => {
            return item.id === attribute.compareWith.attrId &&
              item.fieldType === 'date' &&
              item.multiplicityGroupId === attribute.multiplicityGroupId && (
                Boolean(attribute.newBlockId) ? item.newBlockId === attribute.newBlockId : Boolean(item.newBlockId) === false
              )
          },
        );
      } else {
        art = items.find(
          (item) => {
            return item.fieldType === 'date' &&
              item.compareWith &&
              item.compareWith.attrId === attribute.id && (
                Boolean(attribute.newBlockId) ? item.newBlockId === attribute.newBlockId : Boolean(item.newBlockId) === false
              )
          },
        );
      }
    }

    const { defaultValueAddress, defaultValueCountry, error } = this.state;
    
    const idIndex = `${item.id}_${index}`;
    const itemValue = getDefaultTo(item, 'value');
    const valueType = trim(getDefaultTo(item, 'valueType'));
    const valueTitle = getDefaultTo(item, 'valueTitle');
    const valueOrDate = isISODate(itemValue)
      ? moment(itemValue).format('DD.MM.YYYY')
      : itemValue;

    const propsComponents = omit(
      {
        ...this.props,
        handleChange: this.handleChange,
        idIndex,
        itemValue,
        valueType,
        valueTitle,
        valueOrDate,
        defaultValueAddress,
        defaultValueCountry,
        setError,
        handleClearFieldAutocomplete: this.handleClearFieldAutocomplete,
        getSuggestionValue: this.getSuggestionValue,
        multiline: resizeable,
        onBlur: this.onBlur,
        error: this.props.errorField || error,
        searchIds: [searchId, art && `${art.id}_${art._attruid}_${art.sortOrder}`]
      },
      [
        'showPopupSearch',
        'groupIndex',
        'multiplicity',
        'multiplicityGroup',
        'multiplicityDelete',
      ],
    );

    if (!isNil(item.modalView)) {
      return (
        <FormControl fullWidth={true}>
          {isModerateError &&
            this.moderateTooltip(
              `tooltip_moderate3_${attrUid}`,
              moderateError.comment,
            )
              // (
              // <MuiThemeProvider
              //   theme={this.state.bottom ? TextTooltipModeration : TextTooltipModerationUpper}>
              //   <Tooltip
              //     interactive
              //     id={`tooltip_moderate3_${attrUid}`}
              //     onOpen={this.handleOpen(`tooltip_moderate3_${attrUid}`)}
              //     placement="bottom-end"
              //     title={moderateError.comment}
              //     >
              //     <ModerationIconHolder>
              //       <IconError />
              //     </ModerationIconHolder>
              //   </Tooltip>
              // </MuiThemeProvider>
              // )
            
            }
          <ProductTypeSelectField>
            <ProductTypeSelectLeft>
              <MuiThemeProvider theme={ProductSearchBtn}>
                <Button
                  disabled={disabled}
                  onClick={() =>
                    showPopupSearch(groupIndex, index, item.name, item.value)
                  }>
                  <IconPlus />
                </Button>
              </MuiThemeProvider>
            </ProductTypeSelectLeft>
            <ProductTypeSelectRight>
              <MuiThemeProvider theme={customInputThemeSearch}>
                <InputItem
                  searchIds={[searchId, `${art.id}_${art._attruid}_${art.sortOrder}`]}
                  setError={setError}
                  onBlur={this.onBlur}
                  attrUid={attrUid}
                  id={idIndex}
                  disabled
                  required={required}
                  itemId={item.id}
                  onValidateChange={this.onBlur}
                  name={item.name}
                  label={item.name}
                  placeholder={item.name}
                  value={itemValue}
                  fieldType={item.fieldType}
                  description={t(item.description)}
                  errors={errors}
                  error={isModerateError}
                  onValidate={() => {}}
                  onChange={this.onBlur}
                  onUpdate={() => {}}
                />
                <IconSearchHolder>
                  <IconSearchBlack />
                </IconSearchHolder>
              </MuiThemeProvider>
            </ProductTypeSelectRight>
          </ProductTypeSelectField>
        </FormControl>
      );
    } else if (!isNil(item.url)) {
      const hasMulti = (multiplicity && index > 0) ||
        (multiplicityDelete && (multiplicityGroupId ||
          multiplicityGroupUiId));

      const hasMulti2 = (multiplicity && index > 0) ||
        (multiplicityDelete &&
          (multiplicityGroupId || multiplicityGroupUiId))

      return (
        <MuiThemeProvider theme={ProfileAutocomplete}>
          {isModerateError &&
            this.moderateTooltip(
              `tooltip_moderate_${attrUid}`,
              moderateError.comment,
            )
            // (
            //   <MuiThemeProvider
            //     theme={this.state.bottom ? TextTooltipModeration : TextTooltipModerationUpper}>
            //     <Tooltip
            //       interactive
            //       id={`tooltip_moderate3_${attrUid}`}
            //       onOpen={this.handleOpen(`tooltip_moderate3_${attrUid}`)}
            //       placement="bottom-end"
            //       title={moderateError.comment}
            //       >
            //       <ModerationIconHolder>
            //         <IconError />
            //       </ModerationIconHolder>
            //     </Tooltip>
            //   </MuiThemeProvider>
            //   )
            }
          {!isEmpty(item.valuesTypePreset) ? (
            <ProductTypeSelectField>
              {item.id === 2504 ? (
                <SelectTypeItemType
                  {...propsComponents}
                  onBlur={this.onBlur}
                  valid={!!itemValue || !!valueTitle}
                />
              ) : (
                <SelectTypeItemType
                  {...propsComponents}
                  onBlur={this.onBlur}
                  valid={!!valueTitle}
                />
              )}

              <ProductTypeSelectRight>
                {hasMulti ? (
                  <PackInfoItem>
                    <PackInfoItemMax>
                      <AutocompleteType1
                        {...propsComponents}
                        onBlur={this.onBlur}
                      />
                    </PackInfoItemMax>

                    <ButtonDelete {...propsComponents} />
                  </PackInfoItem>
                ) : (
                  <ProductTypeSelectRight>
                    <AutocompleteType1
                      {...propsComponents}
                      onBlur={this.onBlur}
                    />
                  </ProductTypeSelectRight>
                )}
              </ProductTypeSelectRight>

              
            </ProductTypeSelectField>
          ) : (
            <FormControl fullWidth={true}>
              {hasMulti2 ? (
                <PackInfoItem>
                  <PackInfoItemMax>
                    <AutocompleteType2
                      {...propsComponents}
                      onBlur={this.onBlur}
                    />
                  </PackInfoItemMax>

                  <ButtonDelete {...propsComponents} />
                </PackInfoItem>
              ) : (
                <MuiThemeProvider theme={ProfileAutocomplete}>
                  <AutocompleteType2
                    {...propsComponents}
                    onBlur={this.onBlur}
                  />
                </MuiThemeProvider>
              )}
            </FormControl>
          )}
        </MuiThemeProvider>
      );
    } else if (!isEmpty(item.preset)) {
      const hasMulti3 = (multiplicity && index > 0) ||
        (multiplicityDelete &&
          (multiplicityGroupId || multiplicityGroupUiId))

      return (
        <React.Fragment>
          {isModerateError &&
            this.moderateTooltip(
              `tooltip_moderate2_${attrUid}`,
              moderateError.comment,
            )
            // (
            //   <MuiThemeProvider
            //     theme={this.state.bottom ? TextTooltipModeration : TextTooltipModerationUpper}>
            //     <Tooltip
            //       interactive
            //       id={`tooltip_moderate3_${attrUid}`}
            //       onOpen={this.handleOpen(`tooltip_moderate3_${attrUid}`)}
            //       placement="bottom-end"
            //       title={moderateError.comment}
            //       >
            //       <ModerationIconHolder>
            //         <IconError />
            //       </ModerationIconHolder>
            //     </Tooltip>
            //   </MuiThemeProvider>
            //   )
            }
          {!isEmpty(item.valuesTypePreset) ? (
            <ProductTypeSelectField>
              <SelectTypeItemType
                {...propsComponents}
                onBlur={this.onBlur}
                valid={!!itemValue}
              />
              {item.isPresetOnly ? (
                <ProductTypeSelectRight>
                  {hasMulti3 ? (
                    <PackInfoItem>
                      <PackInfoItemMax>
                        <SelectItemType1
                          {...propsComponents}
                          onBlur={this.onBlur}
                        />
                      </PackInfoItemMax>

                      <ButtonDelete {...propsComponents} />
                    </PackInfoItem>
                  ) : (
                    <SelectItemType1
                      {...propsComponents}
                      onBlur={this.onBlur}
                    />
                  )}
                </ProductTypeSelectRight>
              ) : (
                <ProductTypeSelectRight>
                  {hasMulti3 ? (
                    <PackInfoItem>
                      <PackInfoItemMax>
                        <AutocompleteType3
                          {...propsComponents}
                          onBlur={this.onBlur}
                        />
                      </PackInfoItemMax>

                      <ButtonDelete {...propsComponents} />
                    </PackInfoItem>
                  ) : (
                    <AutocompleteType3
                      {...propsComponents}
                      onBlur={this.onBlur}
                    />
                  )}
                </ProductTypeSelectRight>
              )}
            </ProductTypeSelectField>
          ) : (
            <React.Fragment>
              {item.isPresetOnly ? (
                <FormControl fullWidth={true}>
                  {hasMulti3 ? (
                    <PackInfoItem>
                      <PackInfoItemMax>
                        <SelectItemType2
                          {...propsComponents}
                          onBlur={this.onBlur}
                        />
                      </PackInfoItemMax>

                      <ButtonDelete {...propsComponents} />
                    </PackInfoItem>
                  ) : (
                    <MuiThemeProvider theme={customSelectTheme}>
                      <SelectItemType2
                        {...propsComponents}
                        onBlur={this.onBlur}
                      />
                    </MuiThemeProvider>
                  )}
                </FormControl>
              ) : (
                <FormControl fullWidth={true}>
                  {hasMulti3 ? (
                    <PackInfoItem>
                      <PackInfoItemMax>
                        <AutocompleteType4
                          {...propsComponents}
                          onBlur={this.onBlur}
                        />
                      </PackInfoItemMax>

                      <ButtonDelete {...propsComponents} />
                    </PackInfoItem>
                  ) : (
                    <AutocompleteType4
                      {...propsComponents}
                      onBlur={this.onBlur}
                    />
                  )}
                </FormControl>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      );
    } else {
      const hasMulti4 = (multiplicity && index > 0) ||
        (multiplicityDelete &&
          (multiplicityGroupId || multiplicityGroupUiId))

      return (
        <FormControl fullWidth={true}>
          {isModerateError &&
            this.moderateTooltip(
              `tooltip_moderate4_${attrUid}`,
              moderateError.comment,
            )
            // (
            //   <MuiThemeProvider
            //     theme={this.state.bottom ? TextTooltipModeration : TextTooltipModerationUpper}>
            //     <Tooltip
            //       interactive
            //       id={`tooltip_moderate3_${attrUid}`}
            //       onOpen={this.handleOpen(`tooltip_moderate3_${attrUid}`)}
            //       placement="bottom-end"
            //       title={moderateError.comment}
            //       >
            //       <ModerationIconHolder>
            //         <IconError />
            //       </ModerationIconHolder>
            //     </Tooltip>
            //   </MuiThemeProvider>
            //   )
            }
          {!isEmpty(item.valuesTypePreset) ? (
            <ProductTypeSelectField>
              <SelectTypeItemType
                {...propsComponents}
                defaultValueCountry={defaultValueCountry}
                valid={!!valueOrDate}
              />
              <ProductTypeSelectRight>
                {hasMulti4 ? (
                  <PackInfoItem>
                    <PackInfoItemMax>
                      <InputItemType1
                        {...propsComponents}
                        onBlur={this.onBlur}
                      />
                    </PackInfoItemMax>

                    <ButtonDelete {...propsComponents} />
                  </PackInfoItem>
                ) : (
                  <InputItemType1
                    {...propsComponents}
                    onBlur={this.onBlur}
                    defaultValueAddress={defaultValueAddress}
                  />
                )}
              </ProductTypeSelectRight>
            </ProductTypeSelectField>
          ) : (
            <React.Fragment>
              {hasMulti4 ? (
                <PackInfoItem>
                  <PackInfoItemMax>
                    <InputItemType2 {...propsComponents} onBlur={this.onBlur} />
                  </PackInfoItemMax>

                  <ButtonDelete {...propsComponents} />
                </PackInfoItem>
              ) : (
                <InputItemType2 {...propsComponents} onBlur={this.onBlur} />
              )}
            </React.Fragment>
          )}
        </FormControl>
      );
    }
  }
}

GeneralElement.propTypes = {
  t: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
  showPopupSearch: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  handleSuggestionsFetchRequested: PropTypes.func.isRequired,
  multiplicity: PropTypes.bool.isRequired,
  required: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  groupIndex: PropTypes.number.isRequired,
  attrUid: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  suggestions: PropTypes.array,
  isModerateError: PropTypes.bool.isRequired,
  moderateError: PropTypes.object,
  errors: PropTypes.object.isRequired,
  hasPublishedGood: PropTypes.bool.isRequired,
};

export default GeneralElement;
