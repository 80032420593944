import * as React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import {
  FormControl,
  MuiThemeProvider,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';
import {
  MoreIcon,
  TextTooltip,
  TextTooltipUpper,
} from '../../common/styles/Common.styled';
import { Button } from '@material-ui/core';
// import { validateText } from '../../pages/ducks/utils.actions';
import { IconMenuClose } from '../../common/icons/customIcons';
import { throwNotification } from '../../common/structure';

class CustomInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dirty: false,
      open: false,
      isHiddenText: false,
      bottom: true,
      position: 0,
      labelError: '',
    };
    this.textInput = this.props.inputRef || React.createRef();
    this.validateDebounce = _.debounce(this.validateString, 1000);
    this.uidItem = this.props.attrUid
      ? `customInput_${this.props.attrUid}`
      : _.uniqueId('customInput_uid_');
  }

  shouldComponentUpdate(nextProps, nextState) {
    const equal = [!_.isEqual(this.state, nextState)];
    const nameProps = [
      'placeholder',
      'value',
      'error',
      'label',
      'disabled',
      'required',
      'valid',
    ];
    _.forEach(nameProps, (i) =>
      equal.push(!_.isEqual(this.props[i], nextProps[i])),
    );
    return _.some(equal);
  }

  componentDidMount() {
    this.handleHiddenText(_.trimStart(this.props.value));
  }

  componentDidUpdate(prevProps) {
    const value = _.trimStart(this.props.value);
    if (!prevProps.value && !!value) {
      this.handleHiddenText(value);
    }
  }

  componentWillUnmount() {
    this.validateDebounce.cancel();
    this.onValidate();
  }

  onValidate = (value = '') => {
    if (_.isFunction(this.props.onValidate)) {
      this.props.onValidate(this.uidItem, value);
    }
  };

  handleHiddenText = (newValue) => {
    let isHiddenText = false;
    const elemDiv = document.getElementById(`div-${this.uidItem}`);
    if (!_.isNil(elemDiv)) {
      elemDiv.innerText = newValue;
      isHiddenText = !!newValue
        ? elemDiv.clientWidth > this.textInput.current.clientWidth - 60
        : false;
    }

    if (this.state.isHiddenText !== isHiddenText) {
      this.setState({ isHiddenText });
    }
  };

  handleClear = () => {
    const { onChange, name, required } = this.props;

    if (onChange && !required) {
      onChange('', name);
    }
  };

  handleChange = (event) => {
    const value = _.trimStart(event.target.value);

    this.handleHiddenText(value);

    if (
      !_.isUndefined(this.props.maxLength) &&
      value.length > this.props.maxLength
    ) {
      return;
    }

    if (!this.state.dirty) {
      this.setState({ dirty: true });
    }

    const v =
      this.props.type === 'number' && value !== '' ? Number(value) : value;

    if (this.props.isValidateText) {
      this.validateDebounce(v);
    }
    const name = _.defaultTo(event.target.name, null);
    this.props.onChange(v, name);
  };

  handlePaste = (event) => {
    const { t } = this.props;
    if (
      _.includes(
        [
          'inn',
          'gcp',
          'gln',
          'count',
          'width',
          'length',
          'height',
          'weight',
          'ITN',
        ],
        this.props.name,
      )
    ) {
      const regex = /[^0-9]/g;
      const clearValue = _.defaultTo(event.target.value, '');
      let clipboardData, pastedData;
      event.stopPropagation();
      event.preventDefault();
      clipboardData = event.clipboardData;
      pastedData = clipboardData.getData('Text');
      // regex.test(pastedData) 
      //   ? this.props.onPaste(clearValue)
      //   : (this.props.name === 'gcp' || this.props.name === 'inn')
      //     ? this.props.onPaste(event.target.value = pastedData)
      //     : this.props.onPaste(event.target.value = Number(pastedData))
      // this.handleChange(event);
      if (!regex.test(pastedData)) {
        this.props.onPaste(clearValue)
        if (this.props.name === 'gcp') {
          if (pastedData.length < 11) {
            this.props.onPaste(event.target.value = pastedData)
          } else {
            this.props.onPaste(clearValue)
            this.props.throwNotification(
              t('Код GCP должен содержать от 3 до 10 цифр'),
              'error',
            );
          }
        }
        if (this.props.name === 'inn') {
          if (pastedData.length < 15) {
            this.props.onPaste(event.target.value = pastedData)
          } else {
            this.props.onPaste(clearValue)
            this.props.throwNotification(
              t('Налоговый идентификатор должен содержать от 3 до 14 цифр'),
              'error',
            );
          }
        }
      } else this.props.throwNotification(t('Поле содержит только цифры'),
        'error',
      );
    }
    this.handleChange(event);
  };

  onKeyPress = (event) => {
    if (this.props.onKeyPress) {
      this.props.onKeyPress(event.target.name, event.keyCode);
    }
  };

  handleBlur = (event) => {
    const value = _.trimStart(event.target.value);

    if (
      !_.isUndefined(this.props.maxLength) &&
      value.length > this.props.maxLength
    ) {
      return;
    }

    const v =
      this.props.type === 'number' && value !== '' ? Number(value) : value;

    if (this.props.isValidateText) {
      this.validateString(v);
    }

    if (this.props.onBlur) {
      const name = _.defaultTo(event.target.name, null);
      this.props.onUpdate(v, name);
    }
  };

  handleValidateString = (value = '') => {
    if (this.state.labelError || value) {
      this.setState({ labelError: value });
      this.onValidate(value);
    }
  };

  validateString = (value) => {
    if (this.oldValidateValue === value) return;
    if (_.isEmpty(value) || !_.isNaN(_.toNumber(value))) {
      return this.handleValidateString();
    }

    this.oldValidateValue = value;
    // this.props.validateText(value, ({ successed, error }) => {
    //   if (successed && error) {
    //     this.handleValidateString(error);
    //   } else {
    //     this.handleValidateString();
    //   }
    // });
  };

  handleClick = () => _.result(this.props, 'onClick');

  handleOpen = (event) => {
    const element = document.getElementById(`tooltip-${this.uidItem}`);
    if (!_.isNil(element)) {
      const bottom = element.getBoundingClientRect().y > event.clientY;
      if (this.state.bottom !== bottom) {
        this.setState({ bottom });
      }
    }
  };

  mouseBlur = () => this.setState({ open: false });
  mouseFocus = () => this.setState({ open: true });

  renderCleanButton = () => {
    const {
      disabled,
      required,
      infoText,
      hideCleanButton,
      cleanButtonStyles,
    } = this.props;

    const marginRight = infoText ? 35 : 5;

    if (!required && !disabled && !hideCleanButton) {
      return (
        <Button
          style={{
            position: 'absolute',
            right: marginRight,
            width: 30,
            minWidth: 30,
            maxHeight: 30,
            backgroundColor: 'transparent',
            color: 'rgba(0, 0, 0, 0.87)',
            padding: '6px 16px',
            paddingBottom: '8px',
            fontSize: '0.875rem',
            transition:
              'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: 500,
            lineHeight: 1.75,
            borderRadius: 4,
            letterSpacing: '0.02857em',
            textTransform: 'uppercase',
            border: 0,
            cursor: 'pointer',
            margin: 0,
            display: 'inline-flex',
            outline: 0,
            alignItems: 'center',
            userSelect: 'none',
            verticalAlign: 'middle',
            justifyContent: 'center',
            textDecoration: 'none',
            boxShadow: 'none',
            top: '50%',
            marginTop: -7,
            ...cleanButtonStyles,
          }}
          onClick={this.handleClear}>
          <div style={{ width: 20, height: 20, paddingBottom: 5 }}>
            <IconMenuClose style={{ width: 12, height: 12 }} />
          </div>
        </Button>
      );
    }

    return null;
  };

  render() {
    const { isHiddenText, labelError, bottom } = this.state;
    const {
      id,
      name,
      placeholder,
      value,
      type,
      error,
      label,
      disabled,
      autoComplete,
      multiline,
      onUpdate,
      isValidateText,
      required,
      valid,
      shrink,
      helperText,
      rows,
      rowsMax,
      dirty,
      style,
      hideCleanButton,
      infoText,
      attrUid,
      cleanButtonStyles,
      onValidate,
      t,
      ...restProps
    } = this.props;
    const isError =
      (error && _.defaultTo(dirty, this.state.dirty)) || !!labelError;

    return (
      <FormControl style={{ position: 'relative' }} fullWidth={true}>
        <TextField
          {...restProps}
          id={id}
          name={name}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={this.handleChange}
          onPaste={this.handlePaste}
          onKeyUp={this.onKeyPress}
          onBlur={this.handleBlur}
          onClick={this.handleClick}
          label={labelError || label}
          autoComplete={autoComplete}
          multiline={multiline}
          rows={rows}
          rowsMax={rowsMax}
          helperText={helperText}
          disabled={disabled}
          required={required}
          error={error}
          inputProps={{ valid: _.toString(required && !!valid && !isError) }}
          InputProps={{ disableUnderline: true }}
          InputLabelProps={shrink ? { shrink: true } : {}}
          inputRef={this.textInput}
          style={style}
        />
        {this.renderCleanButton()}
        <MuiThemeProvider theme={bottom ? TextTooltip : TextTooltipUpper}>
          <Tooltip
            id={`tooltip-${this.uidItem}`}
            onOpen={this.handleOpen}
            open={this.state.open}
            placement="bottom-end"
            title={value}>
            <MoreIcon>
              {isHiddenText && (
                <MoreHoriz
                  style={{ cursor: 'pointer' }}
                  tabIndex="1"
                  onMouseLeave={this.mouseBlur}
                  onMouseEnter={this.mouseFocus}
                />
              )}
            </MoreIcon>
          </Tooltip>
        </MuiThemeProvider>
        <div
          id={`div-${this.uidItem}`}
          style={{
            width: 'auto',
            display: 'inline-block',
            visibility: 'hidden',
            position: 'fixed',
            overflow: 'auto',
            fontWeight: 600,
            fontSize: '16px',
          }}
        />
      </FormControl>
    );
  }
}

CustomInput.propTypes = {
  onChange: PropTypes.func,
  onUpdate: PropTypes.func,
  onClick: PropTypes.func,
  onKeyPress: PropTypes.func,
  validateText: PropTypes.func,
  isValidateText: PropTypes.bool.isRequired,
  onValidate: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  attrUid: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  error: PropTypes.bool,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  autoComplete: PropTypes.string,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  rowsMax: PropTypes.number,
  required: PropTypes.bool,
  valid: PropTypes.bool,
  shrink: PropTypes.bool,
  helperText: PropTypes.string,
  dirty: PropTypes.bool,
  maxLength: PropTypes.number,
  inputRef: PropTypes.object,
  handleClear: PropTypes.func,
  infoText: PropTypes.bool,
  throwNotification: PropTypes.func.isRequired,
};

CustomInput.defaultProps = {
  multiline: false,
  onBlur: true,
  onUpdate: () => { },
  required: false,
  shrink: false,
  dirty: undefined,
  isValidateText: false,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      // validateText: (text, cbk) => validateText(text, cbk),
      throwNotification: (message, type = 'error') =>
        throwNotification(message, type),
    },
    dispatch,
  );
};

export default connect(null, mapDispatchToProps)(CustomInput);