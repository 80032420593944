import * as React from 'react';
import * as PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { debounce, defaultTo, isEqual } from 'lodash';
import _ from 'lodash';
import {CustomInput} from '../../../../../components';
import {IconPlusBlue} from '../../../../../common/icons/customIcons';
import {
  SubItemContainer,  
  SubItemGcp,
  SubItemGln,    
} from '../styled/SubAccountItem.styled';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MuiThemeProvider,
} from '@material-ui/core';
import { sendSubAccountGCP} from '../../../ducks/SubAccount/SubAccount.actions';
import { IconClose } from '../../../../../common/icons/customIcons';
import * as selectors from '../../../ducks/Profile/Profile.selectors';
import { throwNotification } from '../../../../../common/structure';
import {
  defaultTheme,
  DialogTitleStyled,
} from '../../../../../common/styles/Common.styled';
import { CreationCloseBtns } from '../../../Profile.styled';
import { SubAccountDialog } from '../styled/AssignSubAccountModal.styled';

class addSubAccountGCP extends React.Component {
  constructor(props) {
    super(props);
    this.state = {      
      subAccount: {
        id: _.uniqueId('companyId_'),       
        gcp:[],        
      },
      errors: {},
      validation: false,
      inputCount: 1,
    };
  }

  updateItem = (id, fieldData) => {
    let subAccount = _.cloneDeep(this.state.subAccount);
    if (subAccount.id === id) subAccount = _.assign({}, subAccount, fieldData);    
    this.setState({ subAccount });
  };

  handleErrors = (id, error) => {
    this.setState((prevState) => ({
      errors: { ...prevState.errors, [id]: error },
    }));
  };
   
  gcpIsValid = (gcp) => _.includes([3, 4, 5, 6, 7, 8, 9, 10], _.size(_.toString(gcp)));


  isGCPValid = (gcpArray, excludedValues) => {
    let isValid = true;
    const uniqueValues = [];
  
    for (let i = 0; i < gcpArray.length; i++) {
      const gcp = gcpArray[i];
      if (gcp === null) {
      } else if (excludedValues.includes(gcp) || uniqueValues.includes(gcp)) {
        isValid = false;
        break;
      } else {
        const gcpLength = _.toString(gcp).length;
        if (![3, 4, 5, 6, 7, 8, 9, 10].includes(gcpLength)) {
          isValid = false;
          break;
        } else {
          uniqueValues.push(gcp);
        }
      }
    }
  
    return isValid;
  };
  

  sendSubAccountGCP = () => {
    const {
      t,
      throwError,        
      sendSubAccountGCP,
      handleClose,
      changeGridParams,
      currentSubAccount,
    } = this.props;
    const { subAccount } = this.state;
    const subAccountId =currentSubAccount[0].AccountId;
    
    let data = { ...subAccount };
    delete data.direction;
    
    sendSubAccountGCP(subAccount, subAccountId, (errors) => {     
  
      if (_.isNil(errors)) {        
        handleClose();
        changeGridParams();
      }
    });
  };

  onValidateCensorship = (uidField, errorText) => {
    this.setState((prevState) => {
      const booleanValue = !!errorText;
      const censorshipErrors = booleanValue
        ? { ...prevState.censorshipErrors, [uidField]: booleanValue }
        : _.omit(prevState.censorshipErrors, [uidField]);
      return { censorshipErrors };
    });
  };

  updateData = (selection, data) => {
    if (selection === 'identifier') {
      _.forEach(data, (pack) => {
        _.forEach(['length', 'width', 'height', 'weight'], (i) => {
          if (!_.isNil(pack[i])) {
            pack[i] = Number(pack[i]);
          }
        });
      });
    }
    this.setState((prevState) => ({
      data: { ...prevState.data, [selection]: data },
    }));
  };

  validCbk = (bool) => this.setState({ validation: bool });

  handleChange = (name, index) => (value) => {
    this.setState((prevState) => {
      const subAccount = { ...prevState.subAccount };
      subAccount.gcp = prevState.subAccount.gcp.map((item, i) => {
        if (i === index) {
          return value;
        }
        return item;
      });
      return {
        subAccount,
      };
    });
  };
  

  handlePaste = (name, typeId) => (value) => {
    this.setState((prevState) => {
      const subAccount = { ...prevState.subAccount };
      subAccount.gcp = [...subAccount.gcp];
      return {
        subAccount,
      };
    });
  };
  

  createNewInput = () => {
    this.setState((prevState) => {
      const subAccount = { ...prevState.subAccount };
      subAccount.gcp = [...subAccount.gcp, null];
      return {
        inputCount: prevState.inputCount + 1,
        subAccount,
      };
    });
  }; 

  validateGcpArray = (gcpArray, excludedValues) => {
    const errors = [];
    const uniqueValues = [];
    
    for (let i = 0; i < gcpArray.length; i++) {
      const gcp = gcpArray[i];
      if (gcp === null) {
        errors[i] = ''; 
      } else if (excludedValues.includes(gcp) || uniqueValues.includes(gcp)) {
        errors[i] = 'Префикс GCP с таким значением уже существует'; 
      } else {
        const gcpLength = _.toString(gcp).length;
        if (![4, 5, 6, 7, 8, 9].includes(gcpLength)){
          errors[i] = 'Префикс GCP указан некорректно';
        } else {
          errors[i] = '';
          uniqueValues.push(gcp);
        }
      }
    }
    
    return errors;
  };
  
  
  
  render() {
    const { handleClose, t, typeId, currentSubAccount } = this.props;
    const { subAccount, inputCount } = this.state;    
    const { id } = subAccount; 
    const { GCP, GLN,} = currentSubAccount[0];
    const gcpIsValid = (value) => { _.includes([3, 4, 5, 6, 7, 8, 9, 10],_.size(_.toString(value)));}
    const excludedValues = currentSubAccount[0].GCP; 
    const disabledButtonSendSubAccount = !this.isGCPValid(subAccount.gcp, excludedValues);
    const gcpErrors = this.validateGcpArray(subAccount.gcp, excludedValues);

    const additionalInputs = subAccount.gcp.map((value, index) => (
      <SubItemGcp key={`input_${index}`}>
        <CustomInput
          id={`gcp_${id}_${index}`}
          name="gcp"
          label={gcpErrors[index] || t('GCP компании')}
          onUpdate={() => {}}
          onChange={this.handleChange('gcp', index)} 
          onPaste={this.handlePaste('gcp', typeId)}
          value={defaultTo(value, '')}
          // valid={gcpIsValid}
          error={!!gcpErrors[index]}
          type="text"
          t={t}
        />
      </SubItemGcp>
    ));
    return (
      <MuiThemeProvider theme={SubAccountDialog}>
        <Dialog open>
          <DialogTitle>
            <DialogTitleStyled>{t('Добавление GCP компании')}</DialogTitleStyled>
            <MuiThemeProvider theme={CreationCloseBtns}>
              <Button className="backEmpty" onClick={handleClose}>
                <IconClose />
              </Button>
            </MuiThemeProvider>
          </DialogTitle>

          <DialogContent>
            <MuiThemeProvider key={subAccount.id} theme={defaultTheme}>
              <div>
              <SubItemContainer>
                  <SubItemGcp>
                    <CustomInput
                      id={`gcp_${id}`}
                      name="gcp"
                      label={t('GCP компании')}
                      readOnly
                      disabled={true}
                      value={GCP || ''}
                      type="text"
                      t={t}
                    />
                  </SubItemGcp>     
                  <SubItemGln>
                    <CustomInput
                      id={`gln_${id}`}
                      name="gln"
                      label={t('GLN компании')}              
                      readOnly
                      disabled={true}
                      value={GLN || ''}              
                      type="text"
                    />
                  </SubItemGln>
                  {additionalInputs}          
              </SubItemContainer>
              </div>              
            </MuiThemeProvider>
          </DialogContent>
    <Button 
        onClick={this.createNewInput}>       
        <IconPlusBlue style={{ marginRight: "10px" }} /> {t(' Добавить GCP компании')}
      </Button>
          <DialogActions>
            <Button
              color={'primary'}
              disabled={disabledButtonSendSubAccount}
              onClick={this.sendSubAccountGCP}>
              {t('Подтвердить')}
            </Button>
            <Button color={'secondary'} onClick={handleClose}>
              {t('Отменить')}
            </Button>
          </DialogActions>
        </Dialog>
      </MuiThemeProvider>
    );
  }
}

addSubAccountGCP.propTypes = {
  handleClose: PropTypes.func.isRequired,
  changeGridParams: PropTypes.func.isRequired,
  info: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  sendSubAccountGCP: PropTypes.func.isRequired,
  throwError: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  info: selectors.info(state),
  data: selectors.data(state),
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      sendSubAccountGCP: (data, id, cbk) => sendSubAccountGCP(data, id, cbk),
      throwError: (message) => throwNotification(message, 'error'),
    },
    dispatch,
  );
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(addSubAccountGCP);
